<script setup lang="ts">
import { UploadFilled } from '@element-plus/icons-vue';
import { useFocus } from '@vueuse/core';
import { ElMessage, ElMessageBox, type UploadInstance, type UploadUserFile } from 'element-plus';
import { computed, ref, watch } from 'vue';
import { uploadFile as qiniuUploadFile } from '../utils/qiniu';
import { getQiNiuConfig, setExpiredAfter } from '@/store/modules/configStore';
import { useImageStore } from '@/store';
import { useUploadConfig } from '@/composables';

const imageStore = useImageStore();

const uploadRef = ref<UploadInstance>();
const files = ref<UploadUserFile[]>([]);

const cacheConfig = useUploadConfig();

watch(files, async () => {
  const arr = [...files.value];
  for (const file of arr) {
    // 上传
    if (file.status === 'ready') {
      file.status = 'uploading';

      if (!file.raw) {
        continue;
      }
      const fileRaw = file.raw;

      const expiredAfter = cacheConfig.value.expiredAfter;

      const meta = await getQiNiuConfig(file.name, expiredAfter !== 0);

      if (meta.isExists) {
        const isCover = await ElMessageBox.confirm(`[${file.name}]已经存在, 是否覆盖?`, 'Warning', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
        })
          .then(() => true)
          .catch(() => false);

        if (!isCover) {
          files.value.splice(
            files.value.findIndex(f => f === file),
            1,
          );
          continue;
        }
      }

      const p = qiniuUploadFile(fileRaw, meta, {
        process(percent) {
          if (percent < 10) {
            file.percentage = Number(percent.toFixed(2));
          } else if (percent < 12) {
            file.percentage = 10;
          } else if (percent === 100) {
            file.percentage = 100;
          } else {
            file.percentage = Number((percent - 2).toFixed(2));
          }

          if (percent === 100) {
            file.status = 'success';
          }
        },
      });
      p.then((v) => {
        file.status = 'success';
        // 列表里移除已经删除的
        files.value.splice(
          files.value.findIndex(f => f === file),
          1,
        );

        // 生成链接
        imageStore.addImage({
          url: v,
          name: file.name || 'image',
          file: fileRaw,
          size: fileRaw?.size || 0,
          originSize: fileRaw === file.raw ? 0 : file.raw?.size,
          type: fileRaw.type.replace(/\/.*/, ''),
          subType: fileRaw.type.replace(/.*?\//, ''),
          rawType: fileRaw.type,
        });

        return setExpiredAfter(meta.path, expiredAfter);
      }).catch((err) => {
        ElMessage.error(err);
      });
    }
  }
});

function generateNumericUID() {
  const now = Date.now();
  const random = Math.floor(Math.random() * 100000); // 生成一个五位数的随机数
  return Number.parseInt(`${now}${random}`);
}

const $pasteArea = ref<HTMLElement>();

function registerPasteEvent() {
  const pastePanelEl = $pasteArea.value;

  if (!pastePanelEl) {
    return;
  }
  /**
   * 监听粘贴事件
   */
  pastePanelEl.addEventListener('paste', (e) => {
    console.log('paste');
    // 阻止触发默认的粘贴事件
    e.preventDefault();
    const { items } = e.clipboardData || {};
    if (!items) {
      return;
    }
    let empty = true;
    for (const item of items) {
      if (item.kind === 'file' && item.type.startsWith('image')) {
        const file = item.getAsFile();
        if (!file) {
          continue;
        }
        files.value = files.value.concat({
          name: file.name,
          // 生成一个唯一的数字id
          raw: Object.assign(file, { uid: generateNumericUID() }),
          status: 'ready',
          percentage: 0,
        });
        empty = false;
      }
    }
    if (empty) {
      ElMessage.error('剪贴板中没有图片');
    }
  });

  // 禁用默认的拖拽触发的内容
  document.addEventListener(
    'drop',
    (e) => {
      e.preventDefault();
    },
    true,
  );
  document.addEventListener(
    'dragover',
    (e) => {
      e.preventDefault();
    },
    true,
  );
}
watch($pasteArea, () => {
  registerPasteEvent();
});
const { focused } = useFocus($pasteArea);
const pasteText = computed(() => (focused.value ? '现在你可以粘贴了' : '你也可以点击此处，然后粘贴你要上传的图片'));
</script>

<template>
  <div class="upload-wrapper">
    <el-upload ref="uploadRef" v-model:file-list="files" drag multiple :auto-upload="false">
      <el-icon class="el-icon--upload" style="font-size: 200px;">
        <UploadFilled />
      </el-icon>
      <div class="el-upload__text">
        拖动文件到这里或 <em>点击上传</em>
      </div>
      <template #tip>
        <div class="cv-tip">
          <textarea ref="$pasteArea" />
          <p>{{ pasteText }}</p>
        </div>
      </template>
    </el-upload>
  </div>
</template>

<style lang="scss" scoped>
.upload-wrapper {
  position: relative;
}

.cv-tip {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 0;
  background-color: transparent;

  textarea {
    border: none;
    outline: 0;
    width: 100%;
    height: 100%;
    resize: none;
    background: transparent;
    color: white;
    /* 或者是背景颜色，以隐藏文本 */
    caret-color: transparent;

    /* 隐藏光标 */
    &:focus + p {
      color: var(--el-color-success);
    }
  }

  p {
    position: absolute;
    pointer-events: none;
    left: 50%;
    top: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 14px;
  }
}

:deep(ul.el-upload-list) {
  max-width: 666px;
  margin: 20px auto;
}
</style>
