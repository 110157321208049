/* eslint-disable regexp/no-legacy-features */
/* eslint-disable regexp/no-unused-capturing-group */
import { ElMessage } from 'element-plus';

import SparkMD5 from 'spark-md5';
import copy from 'clipboard-copy';

/**
 * 将结果写入的剪贴板
 * @param {string} text
 */
export function copyRes(text: string, msg = '结果已成功复制到剪贴板') {
  // 第三方
  copy(text)
    .then(() => {
      if (msg) {
        ElMessage.success(msg);
      }
    })
    .catch((err) => {
      ElMessage.error(err?.message || '无粘贴板权限');
      ElMessage.warning('不支持自动复制，请手动选择复制');
    });
}

export function base64(s: string) {
  return window.btoa(unescape(encodeURIComponent(s)));
}

export function formatDate(d: Date | number, fmt = 'yyyy-MM-dd hh:mm:ss') {
  if (!(d instanceof Date)) {
    d = new Date(d);
  }
  const o: any = {
    'M+': d.getMonth() + 1, // 月份
    'd+': d.getDate(), // 日
    'h+': d.getHours(), // 小时
    'm+': d.getMinutes(), // 分
    's+': d.getSeconds(), // 秒
    'q+': Math.floor((d.getMonth() + 3) / 3), // 季度
    'S': d.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      `${d.getFullYear()}`.substr(4 - RegExp.$1.length),
    );
  }

  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length),
      );
    }
  }
  return fmt;
}

export function getFileSuffix(str: string) {
  const startIndex = str.lastIndexOf('.');
  return startIndex >= 0 ? str.slice(startIndex) : '';
}

export function getFileMd5Hash(file: File) {
  return new Promise((resolve, reject) => {
    const blobSlice = File.prototype.slice;
    const chunkSize = 2097152; // Read in chunks of 2MB
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    fileReader.onload = function (e) {
      // console.log('read chunk nr', currentChunk + 1, 'of', chunks)
      spark.append(e?.target?.result as any); // Append array buffer
      currentChunk += 1;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        // console.log('finished loading')
        const hashResult = spark.end();
        // console.info('computed hash', hashResult) // Compute hash
        resolve(hashResult);
      }
    };

    fileReader.onerror = function () {
      reject(new Error('oops, something went wrong.'));
    };

    loadNext();
  });
}
