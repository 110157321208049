import * as qiniu from 'qiniu-js';
import { getFileMd5Hash } from './stringUtil';
import type { QiNiuConfig } from '@/store/modules/configStore';

async function uploadFile(file: File, qiniuOps: QiNiuConfig, options?: {
  process?: (percent: number) => void;
}) {
  const { config, domain, token, path } = qiniuOps;
  return new Promise<string>((resolve, reject) => {
    const putExtra = {
      fname: path,
      customVars: {},
    };

    const observable = qiniu.upload(file, putExtra.fname, token, putExtra, config);
    observable.subscribe({
      next(res) {
        // 上传进度
        const { percent } = res.total;
        options?.process?.(percent);
      },
      error(err) {
        reject(err);
      },
      complete() {
        resolve(`http://${domain}/${putExtra.fname}`);
      },
    });
  });
}

async function generateNewFileKey(file: File, prefix = 'mdImg', scope = 'sugar') {
  const md5 = await getFileMd5Hash(file);
  return `${prefix}/${scope}/${md5}`;
}

export {
  uploadFile,
  generateNewFileKey,
};
