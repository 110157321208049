<script setup lang="ts">
import { Document, Mic, VideoCamera } from '@element-plus/icons-vue';
import { ElMessageBox } from 'element-plus';
import { computed } from 'vue';
import { copyRes, formatDate } from '../utils/stringUtil';
import { formatSize } from '@/utils';
import type { IImage } from '@/store/modules/imageStore';
import { useImageStore } from '@/store';

const imageStore = useImageStore();
function copyAddress(url: string) {
  copyRes(url, url);
}

function copyMdAddress(url: string) {
  copyAddress(`![](${url})`);
}
const successImages = computed(() => imageStore.success);

function checkInfo(image: IImage) {
  ElMessageBox({
    title: '文件信息',
    dangerouslyUseHTMLString: true,
    message: `<div class="preview-info">
      <ul>
        <li>文件名：${image.name}</li>  
        <li>链接：<a target="_blank" href=${image.url}>${image.url}</a></li>  
        <li>上传时间：${image.date && formatDate(image.date)}</li>
        <li>大小：${image.size ? formatSize(image.size) : '未知'}</li>  
      </ul>
      </div>`,
  });
}

const proxyUrl = import.meta.env.PUBLIC_API_BASE_URL;
</script>

<template>
  <!-- 链接列表 -->
  <p v-if="successImages?.length > 0" class="title">
    历史上传记录 ↓
  </p>
  <ul class="el-upload-list el-upload-list--text">
    <li v-for="(image, idx) in successImages" :key="idx" class="el-upload-list__item">
      <div class="el-upload-list__item-info">
        <div class="el-upload-list__item-name list-item-link-wrapper">
          <span class="ellipsis left">
            <el-image
              v-if="image.type === 'image'"
              style="width: 50px; height: 50px"
              :src="`${proxyUrl}/proxy?url=${encodeURIComponent(image.url)}`"
              fit="scale-down"
            />
            <div v-else-if="image.type === 'audio'" style="width: 50px; height: 50px">
              <el-icon :size="50">
                <Mic />
              </el-icon>
            </div>
            <div v-else-if="image.type === 'video'" style="width: 50px; height: 50px">
              <el-icon :size="50">
                <VideoCamera />
              </el-icon>
            </div>

            <div v-else style="width: 50px; height: 50px" fit="scale-down">
              <el-icon :size="50">
                <Document />
              </el-icon>
            </div>
            <a
              style="margin-left: 10px; text-align: left; width: 100%;"
              :href="image.url"
              target="_blank"
              class="ellipsis el-upload-list__item-file-name"
              title=""
            >
              {{ image.name }}
            </a>
          </span>
          <span style="width: 160px" class="right">
            <el-button v-if="image.size" style="width: 40px; flex-shrink: 0;" :type="image.originSize ? 'success' : 'warning'" link>
              {{ formatSize(image.size) }}
            </el-button>
            <el-button type="primary" link title="查看图片信息" @click="checkInfo(image)">🔍</el-button>
            <el-button type="success" link title="复制markdown格式图片地址" @click="copyMdAddress(image.url)">md</el-button>
            <el-button type="primary" title="复制图片地址" @click="copyAddress(image.url)">复制链接</el-button>
          </span>
        </div>
      </div>
    </li>
  </ul>
  <!-- <div class="pagination">
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="uploadConfig.pageSize"
      small
      background
      :page-sizes="[20, 50, 100, 200]"
      layout="total, sizes, prev, pager, next"
      :total="successImages.length"
    />
  </div> -->
</template>

<style lang="scss" scoped>
.title {
  text-align: center;
  font-size: 12px;
}

ul.el-upload-list {
  max-width: 666px;
  margin: 20px auto;
}

.list-item-link-wrapper {
  display: flex;
  justify-content: space-between;

  .left {
    flex: 1;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  span {
    display: flex;
    align-items: center;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination {
  display: flex;
  justify-content: center;

  .el-pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    :deep(.el-pager) {
      margin: 10px;
    }
  }
}
</style>

<style lang="scss">
.preview-info {
  li {
    word-break: break-all;
  }
}
</style>
