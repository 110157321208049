import axios from 'axios';

interface BaseConfig {
  token: string;
  domain: string;
  path: string;
  isExists: boolean;
}
export interface QiNiuConfig extends BaseConfig {
  config: {
    useCdnDomain: boolean;
  };
}

async function getQiNiuConfig(name: string, isTemp = false): Promise<QiNiuConfig> {
  const scope = isTemp ? 'temp' : 'default';
  const path = `${scope}/${name}`;

  const res = await axios.get(`${import.meta.env.PUBLIC_API_BASE_URL}/qiniu/meta`, {
    params: { path },
  });

  return {
    ...res.data.data,
    config: {
      useCdnDomain: true,
    },
  };
}

async function setExpiredAfter(path: string, expiredAfterHours: number) {
  const res = await axios.get(`${import.meta.env.PUBLIC_API_BASE_URL}/qiniu/expired`, {
    params: { path, expiredAfterHours },
  });

  return res;
}

export { getQiNiuConfig, setExpiredAfter };
