<script setup lang="ts">
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useUploadConfig } from '@/composables';
import { useImageStore } from '@/store';
import { copyRes } from '@/utils/stringUtil';

const cacheConfig = useUploadConfig();
const store = useImageStore();
const { success } = storeToRefs(store);
watch(() => success.value.length, () => {
  const uploadItem = success.value[0];
  const { autoCopy, copyType } = cacheConfig.value;
  if (autoCopy) {
    copyRes(copyType === 'url' ? uploadItem.url : `![](${uploadItem.url})`, uploadItem.url);
  }
});
</script>

<template>
  <div class="tool-wrapper">
    <span class="autoCopy">
      <!-- <el-switch v-model="cacheConfig.autoCopy" inline-prompt active-text="自动复制" inactive-text="自动复制" /> -->
      <!-- <el-select
        v-if="cacheConfig.autoCopy" v-model="cacheConfig.copyType" style="width: 100px;"
        placeholder="选择复制类型" size="small"
      >
        <el-option label="链接" value="url" />
        <el-option label="Markdown" value="markdown" />
        <template #empty />

        <template #prefix />
      </el-select> -->

      <el-text>保存时长</el-text>
      <el-select
        v-model="cacheConfig.expiredAfter" style="width: 100px;"
        placeholder="保存时长" size="small"
      >
        <el-option label="无限制" :value="0" />
        <el-option label="1小时" :value="1" />
        <el-option label="2小时" :value="2" />
        <el-option label="5小时" :value="5" />
        <el-option label="12小时" :value="12" />
        <el-option label="24小时" :value="24" />
        <el-option label="36小时" :value="36" />
        <el-option label="48小时" :value="48" />
        <el-option label="3天" :value="72" />
        <el-option label="5天" :value="120" />
        <el-option label="7天" :value="168" />
        <el-option label="31天" :value="744" />
      </el-select>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.tool-wrapper {
  text-align: center;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  > span {
    margin: 2px 6px;
  }
  .el-switch {
    --el-switch-off-color: #ff4949;
  }
}

.autoCopy {
  display: flex;
  align-items: center;

  .el-select {
    margin-left: 10px;
  }
}
</style>
