<script lang="ts" setup>
</script>

<template>
  <header>
    <a class="left" target="_blank" style="text-decoration: none;" href="https://portal.qiniu.com/kodo/bucket">
      <img src="../assets/logo.png">
      <span>七牛</span>
    </a>
  </header>
</template>

<style scoped lang="scss">
header {
  display: flex;
  justify-content: space-between;
  padding: 18px;
}

.left {
  display: flex;
  align-items: center;
  font-size: 14px;

  img {
    height: 40px;
    margin-right: 10px;
  }
}

.right {
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    margin-left: 8px;
  }
}

.expired {
  max-width: 400px;
  margin: 0 auto;
}
</style>
